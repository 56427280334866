body, html {
  max-width: 100%;
  overflow-x: hidden;
}
:root {
  --primary-color: #0038FF;
  --hover-color: #FF00F3;
  --bg-color: #1d0b3e;
}

/* General styles */
.overflow-hidden {
  overflow-x: hidden;
}

/* Navigation styles */
.nav-link {
  color: #fff;
  text-decoration: none;
}

.options:hover,
.nav-link:hover {
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
}

/* Why Us section styles */
.whyUsText1:hover {
  color: #e2a02b;
  cursor: pointer;
}

.whyUsimg1:hover img {
  background-color: #e2a02b;
}

.whyUsText2:hover {
  color: #ff4a73;
  cursor: pointer;
}

.whyUsimg2:hover img {
  background-color: #ff4a73;
}

/* Card styles */
.cardMedia {
  transition: transform 0.3s;
}

.zoom:hover .cardMedia {
  transform: scale(1.1);
  color: #ffffff;
}

.course-card:hover .courseIcon.zoom,
.course-card:hover .courseIcon.zoom svg,
.course-card:hover .courseIcon.zoom path,
.course-card:hover .courseIcon.zoom circle,
.course-card:hover .courseIcon.zoom rect {
  color: #ffffff;
}

/* Button styles */
.registerButton,
.enrollNowButton {
  background: linear-gradient(90deg, #3200C1, #B80EE3);
  color: #ffffff;
}

.registerButton:hover,
.enrollNowButton:hover {
  background-color: var(--hover-color);
  cursor: pointer;
}

@media (max-width: 1024px) {
  .register-container {
    padding-left: 20px;
    padding-right: 20px;
     
  }
}

@media (max-width: 768px) {
  .register-title {
    font-size: 24px;
  }

  .register-subtitle {
    font-size: 14px;
  }

  .register-form {
    padding: 15px;
  }

  .input-field {
    font-size: 14px;
  }

  .register-button {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .register-title {
    font-size: 20px;
  }

  .register-subtitle {
    font-size: 12px;
  }

  .register-form {
    padding: 10px;
  }

  .input-field {
    font-size: 12px;
  }

  .register-button {
    font-size: 12px;
  }
}
/* Animations */
@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-up-animation {
  animation: slideUp 2s ease-in-out;
}

.slide-in-from-right {
  animation: slideInFromRight 1.5s ease-in-out;
}

.slide-in-from-left {
  animation: slideInFromLeft 1.5s ease-in-out;
}

.visible {
  visibility: visible;
}

/* FAQ styles */
.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 510px;
  margin: 0 auto;
}

.faq-item {
  margin-bottom: 28px;
  width: 100%;
}

.question-container {
  width: 100%;
  height: auto;
  min-height: 61px;
  background-color: var(--bg-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 12px 12px 6px 6px;
  cursor: pointer;
  box-sizing: border-box;
}

.question-container:hover {
  background-color: #181036;
}

.answer {
  width: 100%;
  height: auto;
  max-height: 0;
  overflow: hidden;
  background-color: var(--bg-color);
  transition: max-height 0.2s ease, padding 0.2s ease;
  color: #bababa;
  font-family: 'Spartan', sans-serif;
  font-size: 15px;
  margin-bottom: 12px;
  text-align: left;
  border-radius: 0 0 12px 12px;
  padding: 0 20px;
  box-sizing: border-box;
}


.answer.open {
  max-height: 1000px; 
  padding: 10px 20px;;
}



/* Footer styles */
.footer-container:hover .optionsfooter {
  color: var(--primary-color);
}

.whatsapp{
  color: #ffffff;
}